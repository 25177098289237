import axios from 'axios'



export const RegisterUser = (payload) => {
  return async (dispatch) => {
    try {
      const res = await axios.post('https://sky-production.up.railway.app/api/register', payload);
      const data = res.data;

      dispatch({
        type: "USER_REGISTER",
        payload: data
      });
    } catch (error) {
      console.error('Error al crear un USUARIO:', error);
      // Puedes dispatchar una acción de error si es necesario.
    }
  };
};
export const loginAdmin = (email, password) => {
  return async (dispatch) => {
    try {
      const response = await axios.post("https://sky-production.up.railway.app/api/loginadmin", {
        email,
        password,
      });

      if (response.status === 200 && response.data.token) {
        localStorage.setItem("token", response.data.token);

        dispatch({
          type: "LOGIN_SUCCESS",
          payload: {
            token: response.data.token,
            role: response.data.role,
          },
        });

        return true; // Autenticación exitosa
      } else {
        throw new Error("Error durante el inicio de sesión.");
      }
    } catch (error) {
      dispatch({ type: "LOGIN_ERROR" });
      return false; // Autenticación fallida
    }
  };
};
export const DetailsUser = (userCode) => {
  return async (dispatch) => {
    try {
      const res = await axios.get(`https://sky-production.up.railway.app/api/details/${userCode}`);
      const data = res.data;

      dispatch({
        type: "DETAILS_USER",
        payload: data
      });
    } catch (error) {
      console.error('Error al mostrar los detalles:', error);
      // Puedes dispatchar una acción de error si es necesario.
    }
  };
};


export const allUsers = () => {
  return async (dispatch) => {
    try {
      const res = await axios.get(`https://sky-production.up.railway.app/api/users`);
      const data = res.data;

      dispatch({
        type: "ALL_USER",
        payload: data
      });
    } catch (error) {
      console.error('Error al mostrar todos los usuarios:', error);
    }
  };
};

