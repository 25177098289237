import Login from "../components/Register";

export default function RegisterPage() {
    return (
        <div>
            <div>
                <Login/>
            </div>

        </div>
    )
}