import React, { useState, useEffect, useCallback } from "react";
import { Form } from "antd";
import Button from "@mui/material/Button";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RegisterUser } from "../redux/action";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { DatePicker } from "antd";
import moment from "moment";
import Avatar from "@mui/material/Avatar";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function Register() {
  const dispatch = useDispatch();
  const [imagePreview, setImagePreview] = useState(null);

  const [data, setData] = useState({
    photo: "",
    code: "",
    student: "",
    card_number: "",
    osha_date: "",
    osha_instructor: "",
    eyes_color: "",
    height: "",
    course1: "",
    course2: "",
    course3: "",
    course4: "",
    date1: "",
    date2: "",
    date3: "",
    date4: "",
  });

  const [open, setOpen] = React.useState(false);

  /*   useEffect(() => {
    dispatch(Users());
  }, [dispatch]); */

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleSubmit = (e) => {
    try {
      const formData = new FormData();
      formData.append("photo", data.photo); // Asegúrate de que 'avatar' sea el nombre correcto en tu servidor
      formData.append("code", data.code);
      formData.append("student", data.student);
      formData.append("card_number", data.card_number);
      formData.append("osha_date", data.osha_date);
      formData.append("osha_instructor", data.osha_instructor);
      formData.append("eyes_color", data.eyes_color);
      formData.append("height", data.height);
      formData.append("course1", data.course1);
      formData.append("course2", data.course2);
      formData.append("course3", data.course3);
      formData.append("course4", data.course4);

      formData.append("date1", data.date1);
      formData.append("date2", data.date2);
      formData.append("date3", data.date3);
      formData.append("date4", data.date4);

      dispatch(RegisterUser(formData));
    } catch (error) {
      console.error("Error al registrar", error);
    } finally {
      setOpen(true);
      setImagePreview(null)
      setData({
        photo: "",
        code: "",
        student: "",
        card_number: "",
        osha_date: "",
        osha_instructor: "",
        eyes_color: "",
        height: "",
        course1: "",
        course2: "",
        course3: "",
        course4: "",
        date1: "",
        date2: "",
        date3: "",
        date4: "",
      });
    }
  };

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleImageChange = useCallback(
    (e) => {
      if (e.target.files.length > 0) {
        const file = e.target.files[0];
        getBase64(file, (imageUrl) => {
          setImagePreview(imageUrl);
          setData({
            ...data,
            photo: file,
          });
        });
      }
    },
    [data]
  );
  return (
    <div>
      <div>
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 600,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={handleSubmit}
          autoComplete="off"
          className="register-container"
        >
          <div style={{ marginBottom: "2em" }} className="avatar-preregister">
            <div>
              <Avatar
                src={
                  imagePreview
                    ? imagePreview
                    : data.avatar
                    ? data.avatar
                    : "/static/images/avatar/1.jpg"
                }
                sx={{
                  width: 100,
                  height: 100,
                  objectFit: "cover",
                }}
              ></Avatar>
            </div>

            <div className="input-select-image">
              <input
                type="file"
                name="avatar"
                onChange={handleImageChange}
                accept="image/jpeg, image/png"
              />
            </div>
          </div>
          <div className="input-register">
            <div>
              <label>Student</label>

              <input
                type="text"
                name="student"
                value={data.student}
                onChange={handleChange}
                style={{
                  borderColor: "black",
                  borderWidth: "8px solid #000",
                  borderRadius: 0,
                  height: "2em",
                  width: "100%",
                }}
                required
              />
            </div>
            <div
            // Establece el ancho completo para el label
            >
              <label>Code</label>

              <input
                type="text"
                name="code"
                value={data.code}
                onChange={handleChange}
                style={{
                  borderColor: "black",
                  borderWidth: "8px solid #000",
                  borderRadius: 0,
                  height: "2em",

                  width: "100%",
                }}
              />
            </div>
          </div>
          <div className="input-register">
            <div>
              <label>Osha 30h Card Number</label>
              <input
                type="text"
                name="card_number"
                onChange={handleChange}
                value={data.card_number}
                style={{
                  borderColor: "black",
                  borderWidth: "3px solid #000",
                  borderRadius: 0,
                  height: "2em",

                  width: "100%",
                }}
              />
            </div>
            <div
            // Establece el ancho completo para el label
            >
              <label>Osha Date</label>

              <input
                type="text"
                name="osha_date"
                value={data.osha_date}
                onChange={handleChange}
                style={{
                  borderColor: "black",
                  borderWidth: "8px solid #000",
                  borderRadius: 0,
                  height: "2em",

                  width: "100%",
                }}
              />
            </div>
          </div>

          <div style={{ marginBottom: "2em" }}>
            <div
            // Establece el ancho completo para el label
            >
              <label>Osha Instructor</label>

              <input
                type="text"
                name="osha_instructor"
                value={data.osha_instructor}
                onChange={handleChange}
                style={{
                  borderColor: "black",
                  borderWidth: "8px solid #000",
                  borderRadius: 0,
                  height: "2em",

                  width: "100%",
                }}
              />
            </div>

            <div
            // Establece el ancho completo para el label
            >
              <label>Eyes Color</label>

              <input
                type="text"
                name="eyes_color"
                value={data.eyes_color}
                onChange={handleChange}
                style={{
                  borderColor: "black",
                  borderWidth: "8px solid #000",
                  borderRadius: 0,
                  height: "2em",

                  width: "100%",
                }}
              />
            </div>

            <div style={{ marginBottom: "2em" }}>
              <label>Height</label>

              <input
                type="text"
                name="height"
                value={data.height}
                onChange={handleChange}
                style={{
                  borderColor: "black",
                  borderWidth: "8px solid #000",
                  borderRadius: 0,
                  height: "2em",

                  width: "100%",
                }}
              />
            </div>

            <div style={{ marginBottom: "2em" }}>
              <div>
                <label>Row 1:</label>

                <input
                  type="text"
                  name="course1"
                  value={data.course1}
                  onChange={handleChange}
                  style={{
                    borderColor: "black",
                    borderWidth: "8px solid #000",
                    borderRadius: 0,
                    height: "2em",

                    width: "100%",
                  }}
                />
              </div>
              <div>
                <DatePicker
                  format="MM/DD/YYYY"
                  name="date1"
                  value={data.date1 ? moment(data.date1, "MM/DD/YYYY") : null}
                  onChange={(date, dateString) =>
                    handleChange({
                      target: { name: "date1", value: dateString },
                    })
                  }
                  style={{
                    borderColor: "black",
                    borderWidth: "8px solid #000",
                    borderRadius: 0,
                    height: "2em",
                    width: "100%",
                  }}
                />
              </div>
              <div>
                <label>Row 2:</label>

                <input
                  type="text"
                  name="course2"
                  value={data.course2}
                  onChange={handleChange}
                  style={{
                    borderColor: "black",
                    borderWidth: "8px solid #000",
                    borderRadius: 0,
                    height: "2em",

                    width: "100%",
                  }}
                />
              </div>
              <div>
              <DatePicker
                  format="MM/DD/YYYY"
                  name="date2"
                  value={data.date2 ? moment(data.date2, "MM/DD/YYYY") : null}
                  onChange={(date, dateString) =>
                    handleChange({
                      target: { name: "date2", value: dateString },
                    })
                  }
                  style={{
                    borderColor: "black",
                    borderWidth: "8px solid #000",
                    borderRadius: 0,
                    height: "2em",
                    width: "100%",
                  }}
                />
              </div>
              <div>
                <label>Row 3:</label>

                <input
                  type="text"
                  name="course3"
                  value={data.course3}
                  onChange={handleChange}
                  style={{
                    borderColor: "black",
                    borderWidth: "8px solid #000",
                    borderRadius: 0,
                    height: "2em",

                    width: "100%",
                  }}
                />
              </div>
              <div>
              <DatePicker
                  format="MM/DD/YYYY"
                  name="date3"
                  value={data.date3 ? moment(data.date3, "MM/DD/YYYY") : null}
                  onChange={(date, dateString) =>
                    handleChange({
                      target: { name: "date3", value: dateString },
                    })
                  }
                  style={{
                    borderColor: "black",
                    borderWidth: "8px solid #000",
                    borderRadius: 0,
                    height: "2em",
                    width: "100%",
                  }}
                />
              </div>
              <div>
                <label>Row 4:</label>

                <input
                  type="text"
                  name="course4"
                  value={data.course4}
                  onChange={handleChange}
                  style={{
                    borderColor: "black",
                    borderWidth: "8px solid #000",
                    borderRadius: 0,
                    height: "2em",

                    width: "100%",
                  }}
                />
              </div>
              <div>
              <DatePicker
                  format="MM/DD/YYYY"
                  name="date4"
                  value={data.date4 ? moment(data.date4, "MM/DD/YYYY") : null}
                  onChange={(date, dateString) =>
                    handleChange({
                      target: { name: "date4", value: dateString },
                    })
                  }
                  style={{
                    borderColor: "black",
                    borderWidth: "8px solid #000",
                    borderRadius: 0,
                    height: "2em",
                    width: "100%",
                  }}
                />
              </div>
            </div>

            <div>
              <Stack spacing={2} sx={{ width: "100%" }}>
                <Button type="submit" variant="contained">
                  REGISTRATE
                </Button>
                <Snackbar
                  open={open}
                  autoHideDuration={2000}
                  onClose={handleClose}
                >
                  <Alert
                    onClose={handleClose}
                    severity="success"
                    sx={{ width: "100%" }}
                  >
                    {data.role} creado existosamente
                  </Alert>
                </Snackbar>
              </Stack>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}
