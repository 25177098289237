import * as React from "react";

import Box from "@mui/material/Box";

import styles from "./AllUsers.module.css";
import axios from "axios";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { useSelector, useDispatch } from 'react-redux'
import { allUsers  } from '../redux/action'
import Button from '@mui/material/Button';
export default function AllUsers() {
  const dispatch = useDispatch();
  const [data, setData] = React.useState("");
  const [searchTerm, setSearchTerm] = React.useState("");
  const [generationSearchTerm, setGenerationSearchTerm] = React.useState("");
  const allusers = useSelector(state => state.allusers);
  console.log(allusers);
  React.useEffect(() => {
    dispatch(allUsers())
  }, []);

  return (
    <>
      <div>
       <Box sx={{display: 'flex', justifyContent: 'center', gap: '2em'}}>

      <div className={styles.search}>
          <TextField
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            label="Buscar por nombre..."
          />
        </div>
  
       </Box>

        <div className={styles.boletin_container}>
          <table className={styles.boletin_table}>
            <thead>
              <tr>
                <th>Student</th>
                <th>Code</th>
                <th>Height</th>
                <th>Eyes color</th>
                <th>--</th>

              </tr>
            </thead>
            <tbody>
            {allusers.data && allusers.data.filter(
                (row) =>
                row.student
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase()) 
       
            )
                  .map(
                    (row) =>

                <tr  key={row.id}>
                  
                  <td>{row.student}</td>
                
                  <td>{row.code}</td>
                  <td>{row.height}</td>
                  <td>{row.eyes_color}</td>
                  <td> <Button href={`/details/${row.code}`}  target="_blanck" variant="contained">Show details</Button></td>



                </tr>
              )}
            </tbody>
          </table>
        </div>
  
    </div>

    </>
  );
}
