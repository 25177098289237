import React, { useState } from 'react';


import { BrowserRouter as Router, Routes, Route,Navigate  } from 'react-router-dom';
import DetailsPage from './pages/DetailsPage';
import RegisterPage from './pages/RegisterPage';
import Admin from './pages/Admin';
import AllUsersPage from './pages/AllUsersPage';
import Login from './pages/Login';
import { useSelector } from 'react-redux';

function App() {
const token = useSelector(state => state.token);
console.log(token);

  return (
    <Router>
      <Routes>
    

        <Route path='/details/:userCode' element={<DetailsPage/>}/>
        <Route path='/auth/login/admin' element={<Login/>}/>
 {token ? (

   <Route path='/admin' element={<Admin/>}>
       <Route index element={<RegisterPage/>}/>

       <Route path='register' element={<RegisterPage/>}/>
       <Route path='users' element={<AllUsersPage/>}/>


       </Route>

         ): null}

{token ? (

<Route path='/admin' element={<Navigate to='/auth/login/admin' />} />


      ): null}




      </Routes>
    </Router>
  );
}

export default App;
