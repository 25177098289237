import AllUsers from "../components/AllUsers";

export default function AllUsersPage() {
    return (
        <div>
            <div>
                <AllUsers/>
            </div>
        </div>
    )
}