const initialState = {
 details: [],
 allusers: [],
 token: localStorage.getItem('token'),
  
}


export const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'USER_REGISTER':
      return {
        ...state,
      };
      case 'DETAILS_USER':
      return {
        ...state,
        details: action.payload
      };

      case 'ALL_USER':
        return {
          ...state,
          allusers: action.payload
        };
 
        
          
      


    default: return { ...state }
  }
}






