import React, {useEffect} from 'react';
import './Details.css';
import {useSelector, useDispatch} from 'react-redux'
import { DetailsUser } from '../redux/action';
import { useParams } from 'react-router-dom'


function Details() {
  const { userCode } = useParams();
  const dispatch = useDispatch();
const details = useSelector(state => state.details);

useEffect(() => {
  dispatch(DetailsUser(userCode))
}, [dispatch, userCode]);
  return (
    <div className="container-details" style={{marginTop: "10px"}}>
      <div style={{textAlign: "center"}}>
        <img src="https://app.skybridgerestorationinc.com/v1/images/logo.jpg" height="150" className="imageRotateHorizontal" alt="Logo" />
      </div>
      <br />
      <a style={{textDecoration: 'none', color:'blue'}} href="https://skybridgerestorationinc.com">www.skybridgerestoration.com</a>
      <h3 style={{fontSize: "30px"}}>Courses Information</h3>

      <table width="100%">
        <tbody>
          <tr>
            <td style={{textAlign: "right", paddingRight: "10px", fontWeight: "bold", width: "200px"}}>Code: </td>
            <td style={{textAlign: "left", paddingRight: "10px"}}> {details.data && details.data.code}</td>

            <td rowSpan="7">
              <img src={details.data && details.data.photo} width="150" style={{opacity: 0.2}} className="imageRotateHorizontal" alt="Profile" />
            </td>
          </tr>
          <tr>
            <td style={{textAlign: "right", paddingRight: "10px", fontWeight: "bold"}}>Student: </td>
            <td style={{textAlign: "left", paddingRight: "10px"}}> {details.data && details.data.student}</td>
            <td></td>
          </tr>
          <tr>
            <td style={{textAlign: "right", paddingRight: "10px", fontWeight: "bold", width: "100px"}}>Osha 30h Card Number:</td>
            <td style={{textAlign: "left", paddingRight: "10px"}}> { details.data && details.data.card_number}</td>
          </tr>
          <tr>
            <td style={{textAlign: "right", paddingRight: "10px", fontWeight: "bold", width: "100px"}}>Osha Date:</td>
            <td style={{textAlign: "left", paddingRight: "10px"}}> {details.data && details.data.osha_date}</td>

          </tr>
          <tr>
            <td style={{textAlign: "right", paddingRight: "10px", fontWeight: "bold", width: "100px"}}>Osha Instructor:</td>
            <td style={{textAlign: "left", paddingRight: "10px"}}> {details.data && details.data.osha_instructor}</td>

          </tr>
          <tr>
            <td style={{textAlign: "right", paddingRight: "10px", fontWeight: "bold", width: "100px"}}>Eyes Color:</td>
            <td style={{textAlign: "left", paddingRight: "10px"}}> {details.data && details.data.eyes_color}</td>

          </tr>
          <tr>
            <td style={{textAlign: "right", paddingRight: "10px", fontWeight: "bold", width: "100px"}}>Height:</td>
            <td style={{textAlign: "left", paddingRight: "10px"}}> {details.data && details.data.height}</td>

          </tr>
        </tbody>
      </table>

      <hr />

      <h4>Completed Courses:</h4>
      <table id="guias">
        <tbody>
          <tr>
            <th>Course</th>
            <th>Date</th>
          </tr>
          { details.data && details.data.course1 ? (

            <tr>
            <td>{details.data.course1 }</td>
            <td>{details.data.date1}</td>
          </tr>
              ) : null}
          {details.data &&  details.data.course2 ? (

          <tr>
            <td>{details.data.course2}</td>
            <td>{details.data.date1}</td>
          </tr>
              ) : null}

{ details.data && details.data.course3 ? (

<tr>
  <td>{details.data.course3}</td>
  <td>{details.data.date3}</td>
</tr>
    ) : null}

{details.data && details.data.course4 ? (

<tr>
  <td>{details.data.course4}</td>
  <td>{details.data.date4}</td>
</tr>
    ) : null}

        </tbody>
      </table>

      <br /><hr /><br />


      <br /><br /><br />


    </div>
  );
}

export default Details;
